@font-face {
  font-family: 'asulregular';
  font-style: normal;
  font-weight: normal;
  src: url('../src/assets/fonts/Asul-Regular.woff') format('woff'), url("../src/assets/fonts/Asul-Regular.ttf") format('truetype');
}

@font-face {
  font-family: 'asulbold';
  font-style: normal;
  font-weight: normal;
  src: url('../src/assets/fonts/Asul-Bold.woff') format('woff'), url("../src/assets/fonts/Asul-Bold.ttf") format('truetype');
}

@font-face {
  font-family: 'syncopate-regular';
  font-style: normal;
  font-weight: normal;
  src: url("../src/assets/fonts/Syncopate-Regular.ttf") format('truetype');
}
@font-face {
  font-family: 'syncopate-bold';
  font-style: normal;
  font-weight: normal;
  src: url("../src/assets/fonts/Syncopate-Bold.ttf") format('truetype');
}
@font-face {
  font-family: 'circular-std-italic';
  font-style: normal;
  font-weight: normal;
  src: url('../src/assets/fonts/CircularStd-BookItalic.woff') format('woff'), url("../src/assets/fonts/CircularStd-BookItalic.ttf") format('truetype');
}
@font-face {
  font-family: 'poppins-light';
  font-style: normal;
  font-weight: normal;
  src: url("../src/assets/fonts/Poppins-Light.ttf") format('truetype');
}

* {
  box-sizing: border-box;
}

body,html {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  /*-webkit-font-smoothing: antialiased;*/
  /*-moz-osx-font-smoothing: grayscale;*/
  scroll-behavior: smooth;
  overflow: hidden; /*TO PREVENT MOBILE BROWSER FROM RELOADING WHEN SLIDING DOWN*/
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
















.fade-in-section {
  opacity: 0;
  opacity: 1;
  transform: translateY(40vh);
  visibility: hidden;
  transition: opacity 1200ms ease-out, transform 600ms ease-out,
  visibility 1200ms ease-out;
  will-change: opacity, transform, visibility;
}
.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}
.box {
  padding: 20px;
  margin: 20px 0;
}

.box span {
  background-color: rgba(255, 255, 255, 0.5);
  display: inline-block;
  padding: 5px;
  border-radius: 3px;
}


.first-text {
  padding: 3rem;
}
.second-text {
  padding: 3rem;
}
.third-text {
  padding: 3rem;
}

.setting {
  position: absolute;
  transform: translateY(100vh);
  transition: transform 350ms ease-in-out;
  font-size: 2rem;
}

.first-text.active {
  transform: translateY(0);
}
.second-text.active {
  transform: translateY(3rem);
}.third-text.active {
   transform: translateY(6rem);
 }



/*-----------------------------------------------*/
/*body::-webkit-scrollbar {*/
/*  display: none;*/
/*}*/

.well {
  position: relative;
  overflow: hidden;
  transition: 1s cubic-bezier(0.5, 0, 0.5, 1);
}

.top {
  font-size: 80%;
  color: hsla(0, 0%, 100%, .25);
  position: relative;
  top: -.2em;
  cursor: pointer;
}

.panel {
  position: relative;
  height: 100vh;
  overflow: hidden;
  font-size: 10vmin;
  padding: 1em;
  box-sizing: border-box;
  color: hsla(0, 0%, 100%, .1);
  cursor: default;
}

.panel:nth-child(1) {
  background: #012345;
}

.panel:nth-child(2) {
  background: #123456;
}

.panel:nth-child(3) {
  background: #234567;
}

.panel:nth-child(4) {
  background: #345678;
}

.panel:nth-child(5) {
  background: #456789;
}

:root {
  --sk-size: 1.5rem;
  --sk-color: #fff;
}
.sk-wave {
  width: var(--sk-size);
  height: var(--sk-size);
  display: flex;
  justify-content: space-between;
}
.sk-wave-rect {
  background-color: var(--sk-color);
  height: 100%;
  width: 15%;
  min-width: 15%;
  animation: sk-wave 1.2s infinite ease-in-out;
}
.sk-wave-rect:nth-child(1) { animation-delay: -1.2s; }
.sk-wave-rect:nth-child(2) { animation-delay: -1.1s; }
.sk-wave-rect:nth-child(3) { animation-delay: -1.0s; }
.sk-wave-rect:nth-child(4) { animation-delay: -0.9s; }
.sk-wave-rect:nth-child(5) { animation-delay: -0.8s; }
@keyframes sk-wave {
  0%, 40%, 100% {
    transform: scaleY(0.4);
  } 20% {
      transform: scaleY(1);
    }
}


/*
.owl-carousel .owl-stage {
  display: flex;
}

.owl-carousel .owl-item {
  width: auto;
  height: auto;
}
*/
div.delModal {
  position:absolute;
  background-color: rgba(0, 0, 0, 0.9);
  width:100vw;
  height:100vh;
  z-index: 200;
}
